import 'core-js/stable';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import './css/custom.scss';
import configs from './AppConfig';

Sentry.init({
  dsn: 'https://385070c5083549619cac5337e627f527@o234395.ingest.sentry.io/5562521',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0,
});

window.addEventListener('DOMContentLoaded', () => {
  switch (window.location.pathname) {
    case '/form':
    case '/form.html':
    case '/formPay':
    case '/formPay.html':
    case '/formTf2021':
    case '/formTf2021.html':
      import('./controllers/FormController').then((module) => module.default.initForm());
      break;
    case '/registrazionePlus':
    case '/registrazionePlus.html':
    case '/webinarPlus':
    case '/webinarPlus.html':
    case '/webinar-001':
    case '/webinar-001.html':
    case '/webinar-002':
    case '/webinar-002.html':
    case '/webinar-003':
    case '/webinar-003.html':
    case '/webinar-004':
    case '/webinar-004.html':
    case '/webinar-005':
    case '/webinar-005.html':
    case '/webinar-006':
    case '/webinar-006.html':
    case '/webinar-007':
    case '/webinar-007.html':
    case '/webinar-008':
    case '/webinar-008.html':
    case '/webinar-009':
    case '/webinar-009.html':
    case '/webinar-010':
    case '/webinar-010.html':
    case '/webinar-011':
    case '/webinar-011.html':
    case '/webinar-012':
    case '/webinar-012.html':
      import('./controllers/LoginController').then((module) => {
        module.default.init(configs[window.location.pathname]);
      });
      break;
    case '/':
    case '/index':
    case '/index.html':
    case '/registrazionefree':
    case '/registrazionefree.html':
      import('./controllers/IndexController').then((module) => {
        module.default.init();
      });
      break;
    default:
  }
});
